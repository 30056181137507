<template lang="pug">
div
  Transition(v-bind='transitionClasses')
    div(v-if='showCompare' class='fixed bottom-0 w-full')
      div(class='absolute inset-0 bg-black opacity-80')
      div(class='w-full h-[174px] lg:h-[280px]')
        div(class='m-auto lg:container max-w-[352px] pt-3 lg:pt-14')
          div(class='flex justify-start gap-x-8 lg:gap-x-0 xl:gap-x-14 h-24 lg:h-[120px] w-full')
            div(
              v-for='listing in compare.list.value'
              :key='listing.productId'
              class='relative lg:px-2 h-24 lg:h-[120px] w-24 lg:w-1/3 xl:max-w-[389px] xl:px-0'
            )
              div(class='bg-white flex gap-x-2 rounded h-full p-3 lg:py-5 lg:pr-5')
                img(
                  :key='listing.image.key'
                  class='w-20'
                  :alt='listing.image.title || ""'
                  :src='getImageUrl(listing.image.key, listing.image.filename, 80, 80, 70, "fff")'
                )

                div(class='hidden lg:block')
                  InlineHtml(
                    class='block font-bold text-sm lg:w-[200px] xl:w-[254px] text-ellipsis whitespace-nowrap overflow-hidden'
                    :text='listing.productLineName'
                  )
                  div(class='flex items-center gap-x-2 relative h-7 pt-2 lg:pt-0')
                    Stars(:average-rating='listing.averageRating' :total-reviews='listing.totalReviews' class='flex-none py-1')
                    template(v-if='$sitewideConfig.config.sameDayShippingEnabled && listing.sameDayShipping')
                      SameDayShippingLogo(class='py-1 max-w-24')
                  div
                    span(class='text-gray-darkest text-xs uppercase') From:&nbsp;&nbsp;
                    span(
                      :data-discount='listing.hasDiscount'
                      class='text-lg font-bold mr-2 md:text-2xl'
                      :class='listing.hasDiscount ? "or-product-listing-sale-price text-danger" : "text-gray-darkest"'
                    ) {{ formatCents(listing.salePrice) }}
                    span(v-if='listing.hasDiscount' class='line-through inline-block text-sm text-gray-dark') {{ formatCents(listing.originalPrice) }}

                button(
                  class='absolute top-2 right-2 lg:top-3 lg:right-4 xl:right-3'
                  @click='removeCompareProduct(listing.skuSlug)'
                )
                  img(src='/images/x.svg' alt='delete' class='w-3 h-3 cursor-pointer' width='12' height='12')

            //- Show empty boxes for remaining comparisons
            div(
              v-for='index in 3 - compareCount'
              :key='index'
              class='relative lg:px-2 h-24 lg:h-[120px] w-24 lg:w-1/3 xl:max-w-[389px] xl:px-0'
            )
              div(class='bg-white opacity-25 flex gap-x-2 rounded h-full p-3 lg:py-5 lg:pr-5')
        div(class='flex justify-center gap-x-4 pt-4 lg:pt-8')
          BaseButton(class='p-2 lg:px-8' color='WHITE' :has-custom-padding='true' @click='clearCompareProducts()') Clear
          BaseButton(
            :to='compareUrl'
            class='text-black p-2 lg:px-8'
            :is-disabled='isButtonDisabled'
            color='PRIMARY'
            :has-custom-text-color='true'
            :has-custom-padding='true'
          ) Compare {{ compareCount }}
</template>

<script setup lang="ts">
const compare = useCompare()
const { getImageUrl } = useUrls()
const route = useRoute()

const compareUrl = computed(() => {
  const slugs = compare.list.value.map((product) => {
    return `slug=${product.productLineSlug}/${product.skuSlug}`
  })

  // We need to encode the path so it does not mess with our link
  const path = encodeURIComponent(route.path)

  // Split the fullPath by ? so we can get queries and encode them properly
  const filters = route.fullPath.split('?')[1]

  let url = `/compare/?path=${path}`

  // Separating path and filters into different queries because the ? in fullPath was not
  // being encoded properly when passed through a NuxtLink
  if (filters) url = url + `&filters=${encodeURIComponent(filters)}`

  url = url + `&${slugs.join('&')}`

  return url
})

const showCompare = computed(() => {
  return compare.list.value.length > 0
})

const isButtonDisabled = computed(() => {
  return compare.list.value.length < 2
})

const compareCount = computed(() => {
  return compare.list.value.length
})

function removeCompareProduct(skuSlug: string) {
  compare.removeProduct(skuSlug)
}

function clearCompareProducts() {
  compare.clearProducts()
}

const transitionClasses = {
  'enter-active-class': 'transition-transform ease-linear duration-150',
  'enter-from-class': 'translate-y-full',
  'enter-to-class': 'translate-y-0',
  'leave-active-class': 'transition-transform ease-linear duration-150',
  'leave-from-class': 'translate-y-0',
  'leave-to-class': 'translate-y-full',
}
</script>
